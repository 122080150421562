.custom-navbar{
  /* background-image: linear-gradient(135deg, #FEC5BB, #EAFFD0); */
  /* background-size: cover;
  background-attachment: fixed; */
}
.my-list-container {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
}
.list-container-documents {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
}
.list-container-questions {
  /* border: 1px solid #ddd;
  border-radius: 5px; */
  /* padding: 5px; */
}

.my-list-header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.scrollable-container {
  height: 400px;
  overflow-y: scroll;
}

.scrollable-container::-webkit-scrollbar {
  width: 10px;
}

.scrollable-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

h2 {
    /* text-align: left; */
  }

  .document-name {
    /* font-weight: bold; */
    color: #007bff;
    color: #212529;
    padding: 10px;
  }

.upload-container {
  width: 100%;
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ccc;
  background-color: #f5f5f5;
  position: relative;
}

.drag-text {
  font-size: 1.5rem;
  color: #999;
}

.file-input {
  display: none;
}

.filename {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.upload-btn {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #007bff;
  border: none;
  &:hover {
    background-color: #0069d9;
  }
}

.upload-label {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

p {
  text-align: left;
}

.my-gradient-container {
  /* background-image: linear-gradient( 95.2deg, rgba(173,252,234,1) 26.8%, rgba(192,229,246,1) 64% ); */
}

.column-style {
  background-color: #fff;
}

.column-disabled {
  opacity: 0.5;
  pointer-events: none;
}

svg {
  display: inline;
}

.collapse {
  visibility: inherit;
}

/* .btn {
  background-color: rgb(0, 112, 224);
} */
.btn-black {
  background-color: #212529;
}
.btn-hover:hover {
  text-decoration: underline;
}
.bullet-list ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}
/* body {
  background: linear-gradient(135deg, #FEC5BB, #EAFFD0);
  height: 100%;
} */

/* @media (max-width: 576px) {
  .document-name {
    display: block;
    margin-top: 1rem;
  }
  .my-list-container {
    margin-top: 2rem;
  }
} */

/* #accordion .glyphicon { margin-right:10px; }
.panel-collapse>.list-group .list-group-item:first-child {border-top-right-radius: 0;border-top-left-radius: 0;}
.panel-collapse>.list-group .list-group-item {border-width: 1px 0;}
.panel-collapse>.list-group {margin-bottom: 0;}
.panel-collapse .list-group-item {border-radius:0;} */